<template>
    <div>
        <quote-breadcrumbs :step="4"/>

        <div id="container" class="columns" v-if="policy.quote.product && policy.details.address1 && policy.policy.status === 'policy'">

            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <div class="column is-9">
                    <div class="box b-shdw-3">    
                        <div class="text-center">
                            <h4 class="title">Step 5: Confirmation</h4>
                            <p>Thank you for choosing Covered2Go Travel Insurance!</p>
                            <p>Stay safe and enjoy your travels.</p>
                        </div>
                        <br>
                        <div class="document-sections">
                            <h5 class="text-center">Download your documents here:</h5>

                            <div class="level box docs-section">
                                <div class="level-item">
                                    <a :href="certLink" target="_blank"><i class="fas fa-file-pdf"/> Download your Certificate</a>
                                </div>
                                <div class="level-item">
                                    <a :href="pwLink" target="_blank"><i class="fas fa-file-pdf"/> View Policy Wording</a>
                                </div>
                                <div class="level-item">
                                    <a :href="tobLink" target="_blank"><i class="fas fa-file-pdf"/> View the Terms Of Business</a>
                                </div>
                                <!-- <div class="level-item">
                                    <a :href="'faqs'" target="_blank"><i class="fas fa-question-circle"/> Got a question? Read our FAQs</a>
                                </div> -->
                            </div>
                        </div>
                        <br>
                        <div>
                            Your insurance documents are ready for download immediately here.
                            We have also emailed these to you at the email address that you have provided. If you do not receive the email immediately,
                            please check your 'junk' or 'spam' as some internet service providers may filter the email.
                        </div>
                        <hr>
                        <h5 class="title is-6">We really do hope you won't need to call us for assistance, however, here are the important contact numbers to use if you do:</h5>
                        <div class="info-section">
                            <span class="section-header">Claims and Assistance Helpline:</span> +44 (0)330 660 0785
                        </div>
                        <div class="info-section">
                            <span class="section-header">Covered2go Customer Service Centre:</span> 0333 400 7745 <br>
                            Open 9:00am to 5:30pm Monday - Friday
                        </div>
                        <div class="info-section">
                            <strong>If you require any further information, please do not hesitate to contact us either by phoning or emailing us at <a href="mailto:enquiries@covered2go.co.uk">Email enquiries@covered2go.co.uk</a></strong>
                        </div>

                        <!-- <h5 class="title is-5">Policy Summary</h5>
                        <div class="columns">
                            <div class="column is-3 section-header">Policy Number:</div>
                            <div class="column is-3">C2G6283{{ policy.policy.policynum }}</div>
                        </div>
                        <div class="columns">
                            <div class="column section-header">Policy Type:</div>
                            <div class="column">{{ $c2g.policyType[policy.policy.policy_type] }}</div>
                            <div class="column section-header">Product:</div>
                            <div class="column">{{ $helper.stringCapFirst(policy.policy.policy_product) }}</div>
                        </div>
                        <div class="columns">
                            <div class="column section-header">Travel Area:</div>
                            <div class="column">{{ $c2g.area[policy.policy.policy_area] }}</div>
                            <div class="column section-header">Passengers:</div>
                            <div class="column">{{ policy.policy.total_pax }}</div>
                        </div>
                        <div class="columns">
                            <div class="column section-header">Start Date:</div>
                            <div class="column">{{ startDate }}</div>
                            <div class="column section-header">End Date:</div>
                            <div class="column">{{ endDate }}</div>
                        </div>
                        <div class="columns">
                            <div class="column section-header">Address:</div>
                            <div class="column">{{ `${policy.details.address1}, ${policy.details.address2}, ${policy.details.address3}, ${policy.details.town}` }}</div>
                            <div class="column section-header">Postcode:</div>
                            <div class="column">{{ policy.details.postcode }}</div>
                        </div>
                        <div class="columns">
                            <div class="column section-header">Email:</div>
                            <div class="column">{{ policy.details.email }}</div>
                            <div class="column section-header">Telephone:</div>
                            <div class="column">{{ policy.details.telephone }}</div>
                        </div>
                        <div class="columns" v-for="(p, i) in policy.passengers" :key="p.id">
                            <div class="column is-3 section-header">Passenger {{i+1}}:</div>
                            <div class="column is-3">{{ p.title }} {{ p.forename }} {{ p.surname }} Aged {{ p.age }}</div>
                        </div> -->

                        <br>
                        <div class="level box price-section">
                            <div class="level-item">
                                <!-- <div id="price">Premium Paid (including IPT): &pound;{{ Number(policy.policy.sell_price).toFixed(2) }}</div> --> 
                                <div id="price">Premium Paid {{ Number(policy.policy.ipt) != 0 ? "(including IPT)" : "(IPT Removed)" }}: &pound;{{ Number(policy.policy.sell_price).toFixed(2) }}</div>
                            </div>
                        </div>

                    </div>

                </div>
                
            </transition>

            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <PolicySummary/>
            </transition>
        </div>

        <div class="box b-shdw-2" v-else>
            <div class="text-center">
                <h4 class="title">Outstanding Payment Required</h4>
                <p>You have not completed the payment process, please click <router-link :to="{name:'purchasePolicy'}">here</router-link> to continue.</p>
            </div>
        </div>

    </div>
</template>

<script>
    import QuoteBreadcrumbs from "../components/quote-journey/QuoteBreadcrumbs";
    import { mapActions, mapGetters } from "vuex";
    import { policyAPI } from "../mixins/policy-api";
    import PolicySummary from "../components/quote-journey/confirmation/PolicySummary";

    export default {
        name: "Confirmation",
        data() {
            return {
                certLink: '',
                pwLink: '',
                sdLink: '',
                tobLink: ''
            }
        },
        props: ['id'],
        computed: {
            ...mapGetters({
                policy: 'policy',
            }),
            // Format start date to UK style
            startDate() {
                return this.$moment(this.policy.policy.start_date).format('DD/MM/YYYY');
            },
            // Format end date to UK style
            endDate() {
                return this.$moment(this.policy.policy.end_date).format('DD/MM/YYYY');
            }
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
            })
        },
        mixins: [ policyAPI ],
        components: {
            QuoteBreadcrumbs,
            PolicySummary
        },
        mounted() {
            if(this.policy.policy.status === 'policy') {

                this.$gtag.purchase({
                    "transaction_id": this.policy.policy.policynum,
                    "affiliation": "Covered2Go Site",
                    "value": this.policy.policy.sell_price
                });
                
              //  this.makePolicyLive(this.policy.quote.id).then((res) => { // For test
              //  this.setPolicy(res.data);

             //   this.generateCertificate(this.policy.quote.id).then(() => {
             //   this.sendConfirmationEmail(this.policy.quote.id).then(() => { // For test

                // Get the certificate link from the API
                this.certificateLink(this.policy.quote.id).then((res) => {
                    this.certLink = res.data;
                });
                // Get the policy wording link
                this.policyWordingLink(this.policy.quote.id).then((res) => {
                    this.pwLink = res.data;
                });
                // Get the status disclosure link
                this.statusDisclosureLink().then((res) => {
                    this.sdLink = res.data;
                });
                // Get the terms of business link
                this.termsOfBusinessLink().then((res) => {
                    this.tobLink = res.data;
                });
                // Register an account
                this.$http.post('customer/create_account', {
                    'policynum': this.policy.quote.id
                }).return(() => {
                }).error(err => console.log(err));

             //   });
             //   });
             //   });
            }
        }
    }
</script>

<style lang="scss" scoped>
    h4 {
        padding-bottom: 7px;
        border-bottom: $c2g_orange 4px solid;
        margin-bottom: 5px !important;
        font-size: 28px;
    }
    h5 {
        color: $c2g_orange;
    }
    .section-header {
        color: $c2g_blue;
        font-weight: bold;
    }
    .columns {
        margin-bottom: 10px;
        .column {
            padding-bottom: 5px;
        }
    }
    .info-section {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .document-sections {
        h5 {
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
    // Cost section
    .price-section {
        background-color: $c2g_blue;
        .level-item {
            #price {
                color: white;
                font-weight: bold;
                font-size: 20px;
                padding-right: 10px;
            }
            #ipt {
                color: white;
                font-weight: bold;
            }
        }
    }
    // Bottom documents section
    .docs-section {
        background-color: $c2g_orange;
        .level-item {
            a {
                color: white;
                font-weight: bold;
            }
        }
    }
</style>